import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../admin/admin.service';
import { ResultService } from '../../result/result.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  public electionResultData: any;
  constructor(private resultService: ResultService,
    private spinner: NgxSpinnerService, private adminService: AdminService) { }

  ngOnInit(): void {
    this.getResult();
    this.getActiveElection();
    this.getDesignations();
  }


  public electionData: any;
  getActiveElection() {
    this.adminService.getWithOutToken('get-active-election.json').subscribe((res: any) => {
      console.log(res);
      this.electionData = res.record;
    }, err => {
      console.log(err);
    });
  }

  public results: any = [];
  public designation = [];
  getResult() {
    this.spinner.show();
    this.resultService.get('get-result.json').subscribe((res: any) => {
      console.log(res)
      this.electionResultData = res;
       //this.designation = ['ICA-AP Regional Board Members', 'Chairman', 'ICA-AP Vice President', 'ICA-AP President','ICA Global Youth Member- Africa','ICA Global Youth Member at Large-Asia and Pacific','ICA Global Youth Member -Europe','ICA Global Youth Member -Americas','Youth Representative'];  
      this.spinner.hide();
    }, err => {
      console.log(err);
      this.spinner.hide();
    });

    console.log(this.designation)
  }

  getDesignations() {
    this.spinner.show();
    this.adminService.getWithOutToken('get-designations.json').subscribe((res: any) => {
      console.log(res)
      if(res){
          //res.sort((a, b) => a.name.localeCompare(b.name));
          res.forEach(element => {
              this.designation.push(element.name);
          });
      }
      this.spinner.hide();
    }, err => {
      console.log(err);
      this.spinner.hide();
    })
  }

  short(dgn) {
    if (!this.electionResultData || !this.electionResultData.record) {
      return [];
    }
    
    return this.electionResultData.record.filter(obj => obj.designation.name == dgn);
  }

  // short(dgn) {
  //   var value: any = [];
  //   value = this.electionResultData.record.filter(obj => {
  //     return obj.designation.name == dgn;
  //   });
  //   return value;
  // }
//   is_winned(val, i) {
//     var edl = this.electionResultData.election.election_details.find(x => x.designation == val.designation.id);
//     if (edl) {
//       if (i <= edl.no_of_posts) {
//         return true;
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }

//   }

//   hasDuplicateTotalVotes(results: any[], currentTotalVote: number, currentIndex: number): boolean {
//     for (let j = 0; j < results.length; j++) {
//         if (j !== currentIndex && results[j].total_vote === currentTotalVote ) {
//             return true; // If any other row has the same total vote, return true
//         }
//     }
//     return false; // If no other row has the same total vote, return false
// }

// Function to check for duplicate total votes
hasDuplicateTotalVotes(arr: any[], vote: number, index: number): boolean {
  return arr.some((item, idx) => item.total_vote === vote && idx !== index);
}

// Function to check if the candidate is a winner
  is_winned(val, i) {   
    if(val.total_vote > 0){
      var edl = this.electionResultData.election.election_details.find(x => x.designation == val.designation.id);
    
    if (edl) {
      if (i <= edl.no_of_posts) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
    }
    

  }

  is_vote_valid(total_vote: number): boolean {
    return total_vote > 1;
  }
  
 // Get the minimum vote count specifically for top candidates
  getMinimumVoteCount(): number | null {
    // Extract top candidates based on no_of_candidate limit
    const topCandidatesVotes = this.electionResultData.record
        .slice(0, this.electionResultData.no_of_candidate)
        .map(item => item.total_vote)
        .filter(vote => vote > 0); // Ignore 0 votes

    console.log('Top Candidates Votes (Filtered):', topCandidatesVotes); // Debugging

    // Find the minimum vote count within the top candidates
    return topCandidatesVotes.length > 0 ? Math.min(...topCandidatesVotes) : null;
  }

  // Determine the background color based on the vote count and candidate position
  getVoteColor(totalVote: number, index: number): string {
    const lowestVote = this.getMinimumVoteCount();

    // Debugging to confirm logic
    console.log(`Index: ${index}, Total Vote: ${totalVote}, Lowest Vote: ${lowestVote}`);

    if (totalVote === 0) {
        return '#f1d7d7'; // Red for 0 votes
    } else if (lowestVote !== null && totalVote === lowestVote && index < this.electionResultData.no_of_candidate) {
        return '#e9ed57'; // Yellow for candidates with the lowest non-zero vote count in top range
    } else {
        return '#c3edc3'; // Green otherwise
    }
  }

//   getMinimumVoteCount(): number {
//     console.log(this.electionResultData);
//     // Filter out candidates with 0 votes before finding the minimum
//     const filteredVotes = this.electionResultData.record.map(item => item.total_vote)
//         .filter(vote => vote > 0); // Exclude 0 votes

//     return filteredVotes.length > 0 ? Math.min(...filteredVotes) : null; // Return null if no valid votes exist
// }

// getVoteColor(totalVote: number): string {
//     const lowestVote = this.getMinimumVoteCount(); // Get the lowest vote count (excluding 0)
//     if (totalVote === 0) {
//         return '#f1d7d7'; // Red for 0 votes
//     } else if (lowestVote !== null && totalVote === lowestVote) {
//         return '#e9ed57'; // Yellow for the lowest vote count that is greater than 0
//     } else {
//         return '#c3edc3'; // Green for counts above the lowest
//     }
// }

shouldShowCheckIcon(index: number): boolean {
  // Show fa-check for the first `no_of_candidate` records
  return index < this.electionResultData.election.no_of_candidate;
}
  


}
